import { photo_upload_url } from 'mk/urls/functions';
import { FormControl } from 'mk2/components/forms/FormControl';
import { PhotosUploadConfig } from 'mk2/containers/PhotosUpload/PhotosUpload';
import { hasBuggyPhotoUploadInput, UserAgentParsed } from 'mk2/helpers/detects';
import { MapDispatchToPropsObject } from 'mk2/helpers/types';
import { getRequestUserAgent, AppState } from 'mk2/reducers';
import { OldPhotoUploadResponse, PhotoMimetype } from 'mk2/schemas';
import React from 'react';
import { connect } from 'react-redux';
import { Field, WrappedFieldProps } from 'redux-form';

interface PhotosFileInputPublicProps {
    name: string;
    config: PhotosUploadConfig;
    onUploadFiles(config: PhotosUploadConfig, files: File[]);
}

interface StateProps {
    userAgent: UserAgentParsed;
}

interface DispatchProps {
    // onUploadFiles(formName: string, config: PhotosUploadConfig, files: File[]);
}

type PhotosFileInputProps = PhotosFileInputPublicProps & WrappedFieldProps & StateProps & DispatchProps;

class PhotosFileInput extends React.Component<PhotosFileInputProps> {

    private fileInputElem: HTMLInputElement;
    private androidUploadElem: HTMLAnchorElement;
    private useNativeAndroidUpload: boolean;
    private useGenericFileSelectDialog: boolean;

    constructor(props: PhotosFileInputProps) {
        super(props);

        this.useNativeAndroidUpload = hasBuggyPhotoUploadInput(props.userAgent);
        this.useGenericFileSelectDialog = props.userAgent?.flags?.buggySelectImages;
    }

    public render() {
        const { config, userAgent } = this.props;
        return (
            <div>
                {this.useNativeAndroidUpload ? (
                    /* crazy invisible input
                    we must support android-webkit-4, but it does this: if you call click() method
                    on a display-none file-input, it ignores it. so we have to hide it more creatively
                    and this approach seems to work */
                    <a
                        ref={this.handleAndroidUploadRef}
                        href={`mkn://upload?multi=${config.multiple ? 1 : 0}&url=${photo_upload_url(config.photoType)}`}
                    />
                    ) : (
                    <input
                        id="invisible"
                        ref={this.handleFileInputRef}
                        type="file"
                        accept={
                            // Chrome 66 na Androide ma bug, ze 'Select Image' obrazovka zamrzne,
                            // vyvolaj intent na vyber lubovolneho suboru, nie len intent na vyber obrazkov
                            userAgent?.flags?.buggySelectImages
                                ? undefined
                                : [PhotoMimetype.JPEG, PhotoMimetype.PNG, PhotoMimetype.GIF, PhotoMimetype.WEBP].join(',')
                        }
                        multiple={!!config.multiple}
                        onChange={this.handleOnFileSelected}
                        style={{position: 'absolute', left: -5000, zIndex: -100, width: 0, height: 0}}
                        data-cy="photos-file-input"
                    />
                )}
            </div>
        );
    }

    public componentWillUnmount() {
        if (this.useNativeAndroidUpload && (window as any).mkn_uploadend === this.handleNativeAndroidUploadCallback) {
            (window as any).mkn_uploadend = undefined;
        }
    }

    public openFileExplorer() {
        // console.log('PhotosFormGroup.openFileExplorer', this.photosRef);
        if (this.useNativeAndroidUpload) {
            (window as any).mkn_uploadend = this.handleNativeAndroidUploadCallback;
            this.androidUploadElem.click();
        } else {
            this.fileInputElem.click();
        }
    }

    private handleFileInputRef = (el: HTMLInputElement) => {
        this.fileInputElem = el;
    };

    private handleAndroidUploadRef = (el: HTMLAnchorElement) => {
        this.androidUploadElem = el;
    };

    private handleOnFileSelected = () => {
        const { config, onUploadFiles } = this.props;
        const files: File[] = Array.from(this.fileInputElem.files);
        onUploadFiles(config, files);
        // reset dom value, so that we can select the same file again later
        this.fileInputElem.value = '';
    };

    // callback, ktory vola nativna appka spat do stranky ked dokonci upload subor(ov)
    private handleNativeAndroidUploadCallback = (res: OldPhotoUploadResponse) => {
        // console.log('handleNativeAndroidUploadCallback', res);
        // FIXME not implemented yet
    };
}
function mapStateToProps(state: AppState, ownProps: PhotosFileInputPublicProps & WrappedFieldProps): StateProps {
    return {
        userAgent: getRequestUserAgent(state),
    };
}
const mapDispatchToProps: MapDispatchToPropsObject<DispatchProps> = {
    // onUploadFiles: photosUploadTrigger,
};

const ConnectedPhotosFileInput =
    connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(PhotosFileInput);

/**
 * hidden input field, that can show file open dialog and triggers upload of selected photos
 */
export class PhotosFieldNew extends React.Component<PhotosFileInputPublicProps> {
    private fileInputRef: React.RefObject<PhotosFileInput> = React.createRef();

    public render() {
        return (
            <Field
                {...this.props}
                component={this.component}
            />
        );
    }

    public openFileExplorer() {
        if (this.fileInputRef.current) {
            this.fileInputRef.current.openFileExplorer();
        }
    }

    private component = (props) => {
        return (
            <FormControl
                input={props.input}
                meta={props.meta}
                label={props.label}
                marginLeft={props.marginLeft}
                bottomBorder={false} /* always disable, PhotosFieldNew is a hidden input field with 0px height */
                minimizedLabel={true /* always minimize, otherwise label overlaps with this field *//* tslint:disable-line */}
            >
                <ConnectedPhotosFileInput {...props} ref={this.fileInputRef} />
            </FormControl>
        );
    };
}
