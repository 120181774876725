import cx from 'classnames';
import React, { useRef } from 'react';
import { useDrag, useDrop, DragPreviewImage, DropTargetMonitor, XYCoord } from 'react-dnd';
import styles from './DraggableCard.mscss';

interface OwnProps {
    className?: string;
    id: any;
    index: number;
    previewImage?: string;
    moveOffset?: number;
    disabled?: boolean;

    onMove(dragIndex: number, hoverIndex: number);
}

enum ItemType {
    CARD = 'card',
}

interface DragItem {
    index: number;
    id: string;
    type: string;
}

export const DraggableCard: React.FunctionComponent<OwnProps> = React.memo<React.PropsWithChildren<OwnProps>>(({
    className, id, children, index, onMove, previewImage,
    disabled = false, moveOffset = 30,
}) => {
    const ref = useRef<HTMLDivElement>(null);

    const [{ isDragging }, drag, preview] = useDrag({
        item: { type: ItemType.CARD, id, index },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const [, drop] = useDrop({
        accept: ItemType.CARD,
        hover(item: DragItem, monitor: DropTargetMonitor) {
            if (!ref.current) {
                return;
            }

            const dragIndex = item.index;
            const hoverIndex = index;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }

            // mouse must be within the hover card to perform the move (30px from the edge)
            const hoverBoundingRect = ref.current!.getBoundingClientRect();
            const clientOffset = monitor.getClientOffset();
            const height = hoverBoundingRect.bottom - hoverBoundingRect.top;
            const width = hoverBoundingRect.right - hoverBoundingRect.left;
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
            const hoverClientX = (clientOffset as XYCoord).x - hoverBoundingRect.left;

            if (
                moveOffset < hoverClientX &&
                hoverClientX < width - moveOffset &&
                moveOffset < hoverClientY &&
                hoverClientY < height - moveOffset
            ) {
                onMove(dragIndex, hoverIndex);
                item.index = hoverIndex;
            }
        },
    });

    const opacity = isDragging ? 0.5 : 1;

    if (!disabled) {
        drag(drop(ref));
    }

    return (
        <div ref={ref} style={{ opacity }} className={cx(styles.DraggableCard, className)}>
            {previewImage && <DragPreviewImage connect={preview} src={previewImage} />}
            {children}
        </div>
    );
});
