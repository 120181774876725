import { Location } from 'history';
import { FORM_IS_DIRTY_ARE_YOU_SURE_YOU_WANT_TO_LEAVE, TOAST_ERRORS_IN_FORM } from 'mk/autogenerated/translations/mkReduxForm.11b50db8b8b87ff691acebfa03bd1602'
import PhotosUploadScreen from 'mk2/apps/forum/containers/PhotosUploadScreen/PhotosUploadScreen';
import { FormPageType } from 'mk2/constants/enums';
import { PhotosUploadConfig } from 'mk2/containers/PhotosUpload/PhotosUpload';
import { mkReduxFormCancelPage, mkReduxFormClosePage, mkReduxFormOpenPage } from 'mk2/helpers/form.actions';
import { showErrorToastAction } from 'mk2/helpers/toasts';
import { MapDispatchToPropsObject } from 'mk2/helpers/types';
import Loadable from 'mk2/helpers/Loadable';
import { getFormState, getRequestDeviceMobile, AppState } from 'mk2/reducers';
import { HistoryLocationState } from 'mk2/services/browserHistory';
import { parse } from 'query-string';
import React from 'react';
import { connect, MapDispatchToPropsFunction } from 'react-redux';
import { Prompt } from 'react-router';
import { bindActionCreators } from 'redux';
import { reduxForm, FormErrors, InjectedFormProps as ReduxFormInjectedProps, SubmissionError } from 'redux-form';

const StickerField = Loadable({
    loader: () => import('mk2/components/forms/StickerField' /* webpackChunkName: "components.forms.StickerField" */).then((mod) => mod.StickerField),
    modules: ['mk2/components/forms/StickerField'],
    webpack: () => [require.resolveWeak('mk2/components/forms/StickerField')],
});

interface MKReduxFormConfig<FormData, Props> {
    formName?: string;
    photosConfig?: PhotosUploadConfig | ((props: Props) => PhotosUploadConfig);
    // onValidate je povinny parameter v MKReduxFormConfig, aby ten kto implementuje form
    // sa vzdy zamyslel, ci ma alebo nema spravit client-side validaciu. Napriklad, ze
    // nejaky field je required a nema zmysel submitnut data na server ak nie je vyplneny.
    onValidate: (values: FormData, props: Props) => (FormErrors<FormData> | Promise<FormErrors<FormData>>);
    onSave: (values: FormData, props: Props) => void;
    onChange?: (newValues: FormData, oldValues: FormData, props: Props) => void;
    disableDirtyCheck?: boolean;
}

// every component where @mkReduxForm() decorator is applied must contain these attributes
interface MKReduxFormOwnProps {}

// aky typ komponentov berie a vracia @mkReduxForm dekorator
type MKReduxFormDecorator<Props, FormData = {}, ErrorType = string> = (
    WrappedComponent: React.ComponentType<Props & MKReduxFormInjectedProps<FormData, Props, ErrorType>>,
) => React.ComponentClass<Props>;

// state props, ktore posielame dole do obaleneho komponenty
interface MKReduxFormStateProps {
    currentFormPage: FormPageType;
    isMobile: boolean;
}

// dispatch props, ktore posielame dole do obaleneho komponenty
interface MKReduxFormDispatchProps {
    onOpenFormPage(formPage: FormPageType);
    onCloseFormPage();
    onCancelFormPage();
}

function prompt(location: Location<HistoryLocationState>) {
    const qs = parse(location.search);
    if (location.state?.formIgnorePrompt || qs.fip === '1') {
        return true;
    } else {
        return FORM_IS_DIRTY_ARE_YOU_SURE_YOU_WANT_TO_LEAVE;
    }
}

export function mkReduxForm<FormData, Props extends MKReduxFormOwnProps>(
    config: MKReduxFormConfig<FormData, Props>,
): MKReduxFormDecorator<Props, FormData> {
    const f = reduxForm<FormData, Props>({
        form: config.formName,
        onSubmit: async (values: FormData, dispatch, props: Props) => {
            if (config.onValidate) {
                const errors = await config.onValidate(values, { ...props, isSubmitting: true });
                if (Object.keys(errors).length) {
                    dispatch(showErrorToastAction(TOAST_ERRORS_IN_FORM));
                    throw new SubmissionError(errors);
                }
            }

            // redux-form set isSubmitting based on value returned
            // because we are submiting async, we need to return Promise
            // and because we are submiting using sagas, we fake this promise as never resolved
            await Promise.resolve().then(() => {
                // musi byt v promise, aby sa akcia START_SUBMIT firovala este pred zavolanim onSave()
                //
                // Pretoze ked onSave() zavola stopSubmit(), fireje tym akciu STOP_SUBMIT a potom sa
                // tieto dve akcie udeju v opacnom poradi (najskor STOP_SUBMIT a potom START_SUBMIT)
                //
                // To sposobi, ze form ostane v zlom stave (isSubmitting = true)
                config.onSave(values, props);
            });

            // Never ending (resolved) story :D
            await new Promise(() => {
                return;
            });
        },
        onChange: !config.onChange
            ? undefined
            : (newValues: Partial<FormData>, dispatch, props: Props, oldValues: Partial<FormData>) => {
                  // vyzera ze redux-form posiela cele FormData a nie iba Partial<FormData>
                  config.onChange(newValues as FormData, oldValues as FormData, props);
              },
    });

    const g = (WrappedComponent: React.ComponentType<Props & MKReduxFormInjectedProps<FormData, Props>>) => {
        class MKFormHoC extends React.Component<Props & MKReduxFormInjectedProps<FormData, Props>> {
            public render() {
                const { dirty, currentFormPage, form } = this.props;

                return (
                    // pouzity fragment a nie div, aby sa flex-layout v parent containeri vztahoval na form fieldy
                    <React.Fragment>
                        {!config.disableDirtyCheck && <Prompt when={dirty} message={prompt} />}
                        {currentFormPage === FormPageType.Stickers ? (
                            <StickerField name="sticker" />
                        ) : currentFormPage === FormPageType.Photos ? (
                            <PhotosUploadScreen
                                formName={form}
                                config={
                                    typeof config.photosConfig === 'function'
                                        ? config.photosConfig(this.props)
                                        : config.photosConfig
                                }
                            />
                        ) : (
                            <WrappedComponent {...this.props} />
                        )}
                    </React.Fragment>
                );
            }

            public componentDidMount() {
                window.addEventListener('orientationchange', this.handleOnResize);
            }

            public componentWillUnmount() {
                window.removeEventListener('orientationchange', this.handleOnResize);
            }

            private handleOnResize = (ev) => {
                const { currentFormPage, onCloseFormPage } = this.props;

                if (currentFormPage === FormPageType.Stickers || currentFormPage === FormPageType.Photos) {
                    onCloseFormPage();
                }
            };
        }

        return connect<
            MKReduxFormStateProps,
            MKReduxFormDispatchProps,
            Props & ReduxFormInjectedProps<FormData, Props>
        >(
            mkReduxFormMapStateToPropsInternal,
            mkReduxFormMapDispatchToPropsInternal,
        )(MKFormHoC);
    };

    return (c: React.ComponentType<Props & MKReduxFormInjectedProps<FormData, Props>>) => f(g(c));
}

// interface, ktory sa vklada do React.Component<?? & MKReduxFormInjectedProps> form componentu
export type MKReduxFormInjectedProps<FormData = {}, P = {}, ErrorType = string> = MKReduxFormStateProps &
    MKReduxFormDispatchProps &
    ReduxFormInjectedProps<FormData, P, ErrorType>;

export const mkReduxFormMapStateToProps = (formName: string) => (state: AppState) =>
    mkReduxFormMapStateToPropsInternal(state, { form: formName });

export const mkReduxFormMapDispatchToProps = (
    formName: string,
): MapDispatchToPropsObject<MKReduxFormDispatchProps> => ({
    onOpenFormPage: (formPage) => mkReduxFormOpenPage(formName, formPage),
    onCloseFormPage: () => mkReduxFormClosePage(formName),
    onCancelFormPage: () => mkReduxFormCancelPage(formName),
});

const mkReduxFormMapStateToPropsInternal = (
    state: AppState,
    { form }: Pick<ReduxFormInjectedProps, 'form'>,
): MKReduxFormStateProps => {
    const formState = getFormState(state, form);
    return {
        currentFormPage: formState.currentFormPage,
        isMobile: getRequestDeviceMobile(state),
    };
};

const mkReduxFormMapDispatchToPropsInternal: MapDispatchToPropsFunction<
    MKReduxFormDispatchProps,
    Pick<ReduxFormInjectedProps, 'form'>
> = (dispatch, { form }): MKReduxFormDispatchProps => bindActionCreators(mkReduxFormMapDispatchToProps(form), dispatch);
