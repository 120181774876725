import cx from 'classnames';
import { ImgCropped, ImgCropMode, OwnPropsWithSrc as ImgCroppedProps } from 'mk2/components/ImgCropped';
import React from 'react';
import styles from './CroppedPreview.mscss';

interface OwnProps {
    className?: string;
    uploadProgress?: number;
}

type Props = OwnProps & ImgCroppedProps;

export class CroppedPreview extends React.PureComponent<Props> {
    public static defaultProps: Partial<Props> = {
        anchorY: 'center',
    };

    public render() {
        const { className, uploadProgress, width, height, mode, ...rest } = this.props;

        return (
            <div
                className={cx(
                    styles.CroppedPreview,
                    className,
                    mode === ImgCropMode.Embed && styles['CroppedPreview--embed'],
                )}
                style={{ width, height }}
            >
                {uploadProgress >= 0 && (
                    <div className={styles.CroppedPreview__progressBar}>
                        <div
                            style={{ width: uploadProgress * 100 + '%' }}
                            className={styles.CroppedPreview__progressBar__progress}
                        />
                    </div>
                )}
                <ImgCropped width={width} height={height} mode={mode} {...rest} />
            </div>
        );
    }
}
