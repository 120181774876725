import React, { useEffect } from 'react';
import { DndProvider as ReactDndProvider, DndProviderProps as ReactDndProviderProps } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

interface OwnProps {
    isMobile: boolean;
}

type Props = OwnProps & Omit<ReactDndProviderProps<any, any>, 'backend'>;

let dragDropTouch;
export const DndProvider: React.FunctionComponent<Props> = React.memo(({
    children, isMobile, ...rest
}) => {
    useEffect(() => {
        // Enable HTML5 support on touch
        if (isMobile && !dragDropTouch) {
            import('drag-drop-touch' /* webpackChunkName: "components.external.DragDropTouch" */).then(
                (mod) => (dragDropTouch = mod),
            );
        }
    }, [isMobile]);

    return <ReactDndProvider backend={HTML5Backend} {...rest}>{children}</ReactDndProvider>;
});

DndProvider.displayName = 'DndProvider';
