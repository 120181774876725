import cx from 'classnames';
import { photoUrl } from 'mk/photo/photoUrl';
import { Img } from 'mk2/components/Img';
import { PhotoEntity } from 'mk2/schemas';
import React from 'react';
import styles from './ImgCropped.mscss';

export enum ImgCropMode {
    Cover,       // whole width x height area is covered. Either height or width is cropped if it overflows.
    CoverWidth,  // width is fully covered, height is cropped if it overflows.
    CoverHeight, // height is fully covered, width is cropped if it overflows.
    Embed,       // whole image is displayed, scaled as much as possible to fit into width x height
}

interface OwnPropsShared {
    className?: string;

    width?: number;
    height?: number;

    mode: ImgCropMode;

    // where to anchor the image
    //   center - pixels from both top and bottom will be cropped
    //   top - pixels from bottom will be cropped
    //   bottom - pixels from top will be cropped
    anchorX?: 'left' | 'center' | 'right';
    anchorY?: 'center' | 'top' | 'bottom';

    /** If true, image is loaded only if it comes to viewport */
    lazy?: boolean;
    alt?: string;
    'data-cy'?: string;
}

export type OwnPropsWithPhoto = OwnPropsShared & {
    photo: PhotoEntity;
    photoVersion: string;

    imgUrl?: never;
    imgWidth?: never;
    imgHeight?: never;
};

export type OwnPropsWithSrc = OwnPropsShared & {
    imgUrl: string;
    imgWidth: number;
    imgHeight: number;

    photo?: never;
    photoVersion?: never;
};

/**
 * Scales images into the box of size width x height.
 *
 * Crops one dimension (width or height) that overflows.
 */
export class ImgCropped extends React.Component<OwnPropsWithPhoto | OwnPropsWithSrc> {

    public static defaultProps: Partial<OwnPropsWithPhoto | OwnPropsWithSrc> = {
        anchorX: 'center',
        anchorY: 'center',
    };

    public render() {
        const { height, width, className, mode, anchorX, anchorY, alt, 'data-cy': dataCy } = this.props;

        // Checks for Cover and Embed when height and width must be set
        if ((mode === ImgCropMode.Cover || mode === ImgCropMode.Embed)
            && (!width || !height)) {
            throw new Error('Width and height must be set for Cover and Embed modes');
        } else if (mode === ImgCropMode.CoverWidth && !width) {
            throw new Error('Width must be set for CoverWidth mode');
        } else if (mode === ImgCropMode.CoverHeight && !height) {
            throw new Error('Height must be set for CoverHeight mode');
        }

        let srcImgHeight: number;
        let srcImgWidth: number;
        let srcImgUrl: string;
        if (this.props.photo) {
            const {photo, photoVersion} = this.props;

            // NOTE: Cannot force this rule now, because we have code where it is used and I don't want to break production
            // If you get this error - please fix parent component to not use cNNNxNNN version of the image
            if (process.env.NODE_ENV !== 'production' && /c(\d+)x\1/.test(photoVersion)) {
                throw new Error('Cannot calculate right aspect ratio when cropped photo version is used');
            }

            srcImgHeight = photo.height;
            srcImgWidth = photo.width;
            srcImgUrl = photoUrl(photo, photoVersion);
        } else {
            const {imgHeight, imgWidth, imgUrl} = this.props;
            srcImgHeight = imgHeight;
            srcImgWidth = imgWidth;
            srcImgUrl = imgUrl;
        }

        let cropAxis: 'height' | 'width';
        switch (mode) {
            case ImgCropMode.Cover: {
                const hscale = srcImgHeight / height;
                const wscale = srcImgWidth / width;

                cropAxis = (hscale >= wscale) ? 'height' : 'width';
                break;
            }
            case ImgCropMode.Embed: {
                const hscale = srcImgHeight / height;
                const wscale = srcImgWidth / width;

                cropAxis = (hscale >= wscale) ? 'width' : 'height';
                break;
            }
            case ImgCropMode.CoverHeight:
                cropAxis = 'width';
                break;
            case ImgCropMode.CoverWidth:
                cropAxis = 'height';
                break;
        }

        let targetImgHeight;
        let targetImgWidth;
        if (cropAxis === 'height') {
            // bude vidno celu sirku obrazka,
            // zmensi obrazok a zachovaj jeho aspect ratio. co preteka na vysku, sa odreze
            targetImgWidth = Math.min(srcImgWidth, width);
            const scale = srcImgWidth / targetImgWidth;
            targetImgHeight = Math.round(srcImgHeight / scale);
        } else {
            // bude vidno celu vysku obrazka,
            // zmensi obrazok a zachovaj jeho aspect ratio. co preteka na sirku, sa odreze
            targetImgHeight = Math.min(srcImgHeight, height);
            const scale = srcImgHeight / targetImgHeight;
            targetImgWidth = Math.round(srcImgWidth / scale);
        }

        return (
            <div
                className={cx(styles.ImgCropped, className, styles[`ImgCropped--y_${anchorY}`], styles[`ImgCropped--x_${anchorX}`])}
                style={{width, height}}
                data-cy={dataCy}
            >
                <Img
                    src={srcImgUrl}
                    height={targetImgHeight}
                    width={targetImgWidth}
                    className={styles.ImgCropped__img}
                    lazy={this.props.lazy}
                    alt={alt}
                />
            </div>
        );
    }
}
