import { PhotosFieldNew } from 'mk2/components/forms/PhotosFieldNew';
import { FormPageType } from 'mk2/constants/enums';
import PhotosUpload, {
    AutoDialogOpenType,
    PhotosUploadConfig,
    PhotoUploadType,
} from 'mk2/containers/PhotosUpload/PhotosUpload';
import { toolbarPreparePhotosUploadTrigger } from 'mk2/containers/Toolbar/Toolbar.actions';
import { PhotoUploadStatus, SuccessfulPhotoUpload } from 'mk2/helpers/form.reducers';
import { MapDispatchToPropsObject } from 'mk2/helpers/types';
import { getRequestDeviceMobile, AppState } from 'mk2/reducers';
import { PhotoEntity, PhotoSchema } from 'mk2/schemas';
import { getDenormalizedEntity } from 'mk2/selectors';
import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import styles from './PhotosUploadScreen.mscss';

interface OwnProps {
    formName: string;
    config: PhotosUploadConfig;
}

interface StateProps {
    isMobile: boolean;
    // loadingState: LoadingState;
    succesfullyUploadedPhotos: PhotoEntity[] | null;
    isAnyPendingUpload: boolean;
    isAnyFailedUpload: boolean;
}

interface DispatchProps {
    onPrepareUploadPhotos(formName: string, config: PhotosUploadConfig, files: File[], processPhotoUploadOnFormPage: FormPageType);
}

export type Props = OwnProps & StateProps & DispatchProps;

class PhotosUploadScreen extends React.Component<Props> {

    private photosFieldRef: PhotosFieldNew;

    public componentDidMount(): void {
        const { config, isAnyFailedUpload, isAnyPendingUpload, succesfullyUploadedPhotos } = this.props;
        if (
            config.autoDialogOpen &&
            !isAnyFailedUpload &&
            !isAnyPendingUpload &&
            (
                config.autoDialogOpen === AutoDialogOpenType.IF_NO_PHOTOS && succesfullyUploadedPhotos.length === 0 ||
                config.autoDialogOpen === AutoDialogOpenType.ALWAYS
            )
        ) {
            this.handleOpenFileExplorer();
        }
    }

    public render() {
        const { formName, config, isAnyFailedUpload, isAnyPendingUpload, succesfullyUploadedPhotos } = this.props;

        if (!config) {
            return null;
        }

        const photoUploadType = config.photoUploadType ? config.photoUploadType : PhotoUploadType.REGULAR_PHOTOS;
        const displayAddNextPhoto = !config.disableUpload && (config.multiple || (
            succesfullyUploadedPhotos.length === 0 &&
            !isAnyFailedUpload &&
            !isAnyPendingUpload
        ));
        return (
            <div className={styles.PhotosUploadScreen}>
                {displayAddNextPhoto && (
                    <PhotosFieldNew
                        key="fileinput"
                        name="photos"
                        config={config}
                        ref={this.handlePhotosFileInputFieldRef}
                        onUploadFiles={this.handleUploadFiles}
                    />
                )}
                <PhotosUpload
                    formName={formName}
                    photoUploadType={photoUploadType}
                    onClickUploadNextPhoto={displayAddNextPhoto ? this.handleOpenFileExplorer : undefined}
                    showPhotoCode={config.showPhotoCode}
                    previewPhotoSize={config.previewPhotoSize}
                    previewSize={config.previewSize}
                    previewNoCrop={config.previewNoCrop}
                    dragPreviewPhotoSize={config.dragPreviewPhotoSize}
                    showMainPhoto={config.showMainPhoto}
                    strollerId={config.strollerId}
                    disableRotate={config.disableRotate}
                    disableCancel={config.disableCancel}
                    disableSort={config.disableSort}
                    disablePreview={config.disablePreview}
                />
                {config.uploadScreenInfo}
            </div>
        );
    }

    private handleOpenFileExplorer = () => {
        // no photos yet, show file open dialog
        this.photosFieldRef.openFileExplorer();
    };

    private handlePhotosFileInputFieldRef = (ref: PhotosFieldNew) => {
        this.photosFieldRef = ref;
    };

    private handleUploadFiles = (config: PhotosUploadConfig, files: File[]) => {
        const { formName, onPrepareUploadPhotos } = this.props;
        onPrepareUploadPhotos(formName, config, files, FormPageType.Photos);
    };

}

function mapStateToProps(state: AppState, ownProps: OwnProps): StateProps {
    const { formName } = ownProps;

    const selector = formValueSelector(formName);
    const currentUploads = selector(state, 'photos') as any[] || [];

    const succesfullyUploadedPhotos: PhotoEntity[] = [];
    let isAnyPendingUpload: boolean = false;
    let isAnyFailedUpload: boolean = false;
    for (let i = 0; i < currentUploads.length; i++) {
        if (currentUploads[i].status === PhotoUploadStatus.SUCCESS) {
            succesfullyUploadedPhotos.push(getDenormalizedEntity<PhotoEntity>(state, PhotoSchema, (currentUploads[i] as SuccessfulPhotoUpload).photoId));
        } else if (currentUploads[i].status === PhotoUploadStatus.PENDING) {
            isAnyPendingUpload = true;
        } else if (currentUploads[i].status === PhotoUploadStatus.FAILURE) {
            isAnyFailedUpload = true;
        }
    }

    return {
        succesfullyUploadedPhotos,
        isAnyPendingUpload,
        isAnyFailedUpload,
        // loadingState: getTopicLoadingState(state, slug),
        isMobile: getRequestDeviceMobile(state),
        // requestUser: getRequestUser(state),
        // baseUrl: getRequestBaseUrl(state),
        // permissions: getRequestPermissions(state),
        // Topic can be null when we are loading data
    };
}

const mapDispatchToProps: MapDispatchToPropsObject<DispatchProps> = {
    onPrepareUploadPhotos: toolbarPreparePhotosUploadTrigger,
};

export default connect(mapStateToProps, mapDispatchToProps)(PhotosUploadScreen);
