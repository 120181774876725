import { photoUrl } from 'mk/photo/photoUrl';
import { DraggableCard } from 'mk2/components/forms/DraggableCard';
import PhotoPreview, { OwnProps as PhotoPreviewOwnProps } from 'mk2/containers/PhotosUpload/PhotoPreview';
import { PhotoUploadStatus, SuccessfulPhotoUpload } from 'mk2/helpers/form.reducers';
import { AppState } from 'mk2/reducers';
import { PhotoEntity, PhotoSchema } from 'mk2/schemas';
import { getDenormalizedEntity } from 'mk2/selectors';
import React from 'react';
import { connect } from 'react-redux';

interface OwnProps extends PhotoPreviewOwnProps {
    className?: string;
    id: any;
    index: number;
    dragPreviewPhotoSize?: string;
    moveOffset?: number;
    onMove(dragIndex: number, hoverIndex: number);
}

interface StateProps {
    uploadedPhoto: PhotoEntity | null; // not null if upload.status === UploadStatus.SUCCESS
}

type Props = OwnProps & StateProps;

const DraggablePhotoPreviewComponent: React.FunctionComponent<Props> = ({
    className,
    id,
    index,
    moveOffset = 30,
    onMove,
    upload,
    uploadedPhoto,
    previewPhotoSize,
    dragPreviewPhotoSize,
    ...rest
}) => (
    <DraggableCard
        className={className}
        key={upload.id}
        id={upload.id}
        index={index}
        moveOffset={moveOffset}
        onMove={onMove}
        previewImage={
            (uploadedPhoto && photoUrl(uploadedPhoto, dragPreviewPhotoSize || previewPhotoSize || 's150x150'))
        }
    >
        <PhotoPreview upload={upload} previewPhotoSize={previewPhotoSize} {...rest} />
    </DraggableCard>
);
DraggablePhotoPreviewComponent.displayName = 'DraggablePhotoPreview';

function mapStateToProps(state: AppState, ownProps: OwnProps): StateProps {
    const { upload } = ownProps;

    const uploadedPhoto: PhotoEntity =
        upload.status === PhotoUploadStatus.SUCCESS
            ? getDenormalizedEntity<PhotoEntity>(state, PhotoSchema, (upload as SuccessfulPhotoUpload).photoId)
            : null;

    return {
        uploadedPhoto,
    };
}

export const DraggablePhotoPreview = connect(mapStateToProps, null)(DraggablePhotoPreviewComponent);
