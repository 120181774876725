import cx from 'classnames';
import React from 'react';
import { WrappedFieldProps } from 'redux-form';
import styles from './FormControl.mscss';

export interface OwnProps {
    // pls nestylujte form controls cez cssko zvonka (fontsize, farby, line-height, layout, ...)
    // vznika tak kopec bugov
    //
    // Radsej pridajte prop a ten poriadne dorieste v uzavretom kode
    // konkretneho form controlu, kde viete ze vam zvonku nikto nemeni assumptions.
    // A otestuje vasu zmenu tu: https://www-cemi.modrykonik.sk/forms-gallery/
    //
    // Splocne veci co platia pre form controls:
    // - vzdy je klikatelna plocha celeho form controlu (nie len elementy v strede)
    // - minimalna vyska form controlu je 55px. ale moze byt aj vacsia, vtedy sa input
    //   roztiahne a zaberie jeho celu vysku
    // - ikony napravo su vycetrovane v obdlzniku sirky 44px
    // - label alebo text inputu su vzdy 12px od bottom border, co je dosiahnute
    // roznym top paddingom, ktory je sucastou klikatelnej zony
    className?: string;

    label?: string; // redux-form 7.x vyzaduje aby label bol string

    // props, ktore ovplyvnuju vzhlad form controlu
    minimizedLabel?: boolean; // vynuti, aby label bol vzdy zmenseny hore
    marginLeft?: boolean; // (ne)ma bocny margin
    paddingRight?: boolean; // (ne)ma bocny padding
    bottomBorder?: boolean; // (ne)kresli border na spodu
    fillRemainingSpace?: boolean; // expand to fill remaining horizontal space (assumes <form> uses display: flex)
    transparent?: boolean;
    isAdminUI?: boolean; // zobrazuje v zelenej farbe (signalizuje admin UI)
    disabled?: boolean;
    input: Pick<WrappedFieldProps['input'], 'name' | 'value'>;
    meta: Pick<WrappedFieldProps['meta'], 'active' | 'touched' | 'error' | 'warning'>;
}

export const FormControl: React.FunctionComponent<OwnProps> = ({
    input,
    meta: { active, touched, error, warning },
    label,
    className,
    children,
    minimizedLabel = false,
    marginLeft = true,
    paddingRight = false,
    bottomBorder = true,
    fillRemainingSpace = false,
    transparent = false,
    isAdminUI = false,
    disabled = false,
}) => (
    <div
        className={cx(className, styles.FormControl, {
            [styles['FormControl--active']]: active,
            [styles['FormControl--value']]:
                (Array.isArray(input.value) ? input.value.length > 0 : !!input.value || input.value === 0) ||
                minimizedLabel,
            [styles['FormControl--fillRemainingSpace']]: fillRemainingSpace,
            [styles['FormControl--transparent']]: transparent,
            [styles['FormControl--admin']]: isAdminUI,
            [styles['FormControl--disabled']]: disabled,
        })}
        data-cy={`form-control--${input.name}`}
    >
        <div
            className={cx(
                styles.FormControl__wrapper,
                (!bottomBorder || (touched && (!!warning || !!error))) && styles['FormControl__wrapper--noBorder'],
                !marginLeft && styles['FormControl__wrapper--noMarginLeft'],
                paddingRight && styles['FormControl__wrapper--paddingRight'],
                fillRemainingSpace && styles['FormControl__wrapper--fillRemainingSpace'],
            )}
        >
            {!!label /* absolutely positioned label, has either normal or minimized size */ && (
                <label
                    htmlFor={input.name}
                    className={cx(
                        styles.FormControl__label,
                        isAdminUI && styles['FormControl__label--admin'],
                        touched && !!warning && styles['FormControl__label--warning'],
                        touched && !!error && styles['FormControl__label--error'],
                    )}
                >
                    {label}
                </label>
            )}

            {children/* main input component */}
        </div>

        {touched && error && (
            <div data-cy={`form-control-error--${input.name}`} className={styles.FormControl__error}>
                {error}
            </div>
        )}

        {touched && warning && (
            <div data-cy={`form-control-warning--${input.name}`} className={styles.FormControl__warning}>
                {warning}
            </div>
        )}
    </div>
);
