import cx from 'classnames';
import { ADD_NEXT_PHOTO } from 'mk/autogenerated/translations/PhotosUpload.112a5b5e4818d6a76da8bcc50f37c461'
import { DndProvider } from 'mk2/components/DndProvider';
import { DraggablePhotoPreview } from 'mk2/containers/PhotosUpload/DraggablePhotoPreview';
import {
    photoUploadMove,
    photoUploadRemove,
    photoUploadRotate,
} from 'mk2/containers/PhotosUpload/PhotosUpload.actions';
import PhotoPreview from 'mk2/containers/PhotosUpload/PhotoPreview';
import {
    FailedPhotoUpload,
    PendingPhotoUpload,
    PhotoUploadStatus,
    SuccessfulPhotoUpload,
} from 'mk2/helpers/form.reducers';
import { MapDispatchToPropsObject } from 'mk2/helpers/types';
import { getRequestDeviceMobile, AppState } from 'mk2/reducers';
import { PhotoOrientation } from 'mk2/schemas';
import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import styles from './PhotosUpload.mscss';

export enum AutoDialogOpenType {
    NEVER = 0,
    IF_NO_PHOTOS = 1,
    ALWAYS = 2,
}
export interface PhotosUploadConfig {
    photoType: string;
    multiple?: boolean;
    showPhotoCode: boolean;
    showMainPhoto?: boolean;
    photoUploadType?: PhotoUploadType;
    previewPhotoSize?: string;
    previewNoCrop?: boolean;
    previewSize?: 90 | 150;
    dragPreviewPhotoSize?: string;
    disableRotate?: boolean;
    strollerId?: number;
    disableCancel?: boolean;
    disableUpload?: boolean;
    disableSort?: boolean;
    disablePreview?: boolean;
    uploadScreenInfo?: React.ReactNode;
    autoDialogOpen?: AutoDialogOpenType;
}

export interface UploadedPhoto {
    photoId: string;
    orientation: PhotoOrientation;
    status: PhotoUploadStatus;
}

export enum PhotoUploadType {
    REGULAR_PHOTOS,
}

interface OwnProps {
    formName: string;
    photoUploadType: PhotoUploadType;
    showPhotoCode: boolean;
    strollerId?: number;
    disableRotate?: boolean;
    disableCancel?: boolean;
    disableSort?: boolean;
    disablePreview?: boolean;
    previewPhotoSize?: string;
    previewSize?: 90 | 150;
    dragPreviewPhotoSize?: string;
    previewNoCrop?: boolean;
    showMainPhoto?: boolean;
    // funkcia, ktoru ma komponent vyvolat, ked chce otvori file dialog
    // (jej implementacia ma pristup k <input type="file"> elemetu a vie na nom zavolat click() funkciu)
    onClickUploadNextPhoto?();
}

interface StateProps {
    isMobile: boolean;
    uploads: Array<PendingPhotoUpload | SuccessfulPhotoUpload | FailedPhotoUpload>;
}

interface DispatchProps {
    onRemovePhoto(formName: string, uploadId: number);
    onRotatePhoto(formName: string, uploadId: number, orientation: PhotoOrientation);
    onMovePhoto(formName: string, from: number, to: number);
}

class PhotosUpload extends React.Component<OwnProps & StateProps & DispatchProps> {
    public static defaultProps: Partial<OwnProps> = {
        previewSize: 90,
    };

    public render() {
        const {
            uploads,
            photoUploadType,
            previewPhotoSize,
            previewNoCrop,
            previewSize,
            dragPreviewPhotoSize,
            showPhotoCode,
            disableRotate,
            disableCancel,
            disableSort,
            disablePreview,
            onClickUploadNextPhoto,
            showMainPhoto,
            isMobile,
        } = this.props;
        const firstUpload = uploads && uploads.length > 0 ? uploads[0] : null;

        const failedUploads = uploads.filter((u) => u.status === PhotoUploadStatus.FAILURE) as FailedPhotoUpload[];
        return (
            <div className={cx(styles.PhotosUpload, styles[`PhotosUpload--size-${previewSize}`])}>
                {photoUploadType === PhotoUploadType.REGULAR_PHOTOS && uploads && (
                    <DndProvider isMobile={isMobile}>
                        <div className={cx(styles.PhotosUpload__clearfix)}>
                            {!disablePreview && uploads.map((upload, index) => {
                                return disableSort ? (
                                    <PhotoPreview
                                        className={cx(
                                            styles.PhotosUpload__photoBox,
                                            styles[`PhotosUpload__photoBox--size-${previewSize}`],
                                        )}
                                        key={upload.id}
                                        upload={upload}
                                        onCancel={disableCancel ? undefined : this.handleOnRemove}
                                        onRotate={disableRotate ? undefined : this.handleOnRotate}
                                        previewPhotoSize={previewPhotoSize}
                                        showPhotoCode={showPhotoCode}
                                        showStar={showMainPhoto}
                                        isMain={index === 0}
                                        size={previewSize}
                                        noCrop={previewNoCrop}
                                    />
                                ) : (
                                    <DraggablePhotoPreview
                                        key={upload.id}
                                        className={cx(
                                            styles.PhotosUpload__photoBox,
                                            styles[`PhotosUpload__photoBox--size-${previewSize}`],
                                        )}
                                        id={upload.id}
                                        index={index}
                                        moveOffset={0}
                                        onMove={this.onMove}
                                        upload={upload}
                                        onCancel={disableCancel ? undefined : this.handleOnRemove}
                                        onRotate={disableRotate ? undefined : this.handleOnRotate}
                                        previewPhotoSize={previewPhotoSize}
                                        dragPreviewPhotoSize={dragPreviewPhotoSize}
                                        showPhotoCode={showPhotoCode}
                                        showStar={showMainPhoto}
                                        isMain={index === 0}
                                        size={previewSize}
                                        noCrop={previewNoCrop}
                                    />
                                );
                            })}
                            {onClickUploadNextPhoto && (
                                <div
                                    className={cx(
                                        styles.PhotosUpload__photoBox,
                                        styles[`PhotosUpload__photoBox--size-${previewSize}`],
                                        styles.PhotosUpload__addNew,
                                    )}
                                    onClick={onClickUploadNextPhoto}
                                >
                                    <span>{ADD_NEXT_PHOTO}</span>
                                </div>
                            )}
                        </div>
                    </DndProvider>
                )}
                {failedUploads.length > 0 && (
                    <div className={cx(styles.PhotosUpload__error)}>{failedUploads[0].errorMessage}</div>
                )}
            </div>
        );
    }

    private handleOnRemove = (uploadId) => {
        const { formName, onRemovePhoto } = this.props;
        onRemovePhoto(formName, uploadId);
    };

    private handleOnRotate = (uploadId, orientation) => {
        const { formName, onRotatePhoto } = this.props;
        onRotatePhoto(formName, uploadId, orientation);
    };

    private onMove = (from: number, to: number) => {
        const { formName, onMovePhoto } = this.props;
        onMovePhoto(formName, from, to);
    };
}

function mapStateToProps(state: AppState, ownProps: OwnProps): StateProps {
    const { formName } = ownProps;
    const selector = formValueSelector(formName);
    return {
        uploads: (selector(state, 'photos') as any[]) || [],
        isMobile: getRequestDeviceMobile(state),
    };
}

const mapDispatchToProps: MapDispatchToPropsObject<DispatchProps> = {
    onRotatePhoto: photoUploadRotate,
    onRemovePhoto: photoUploadRemove,
    onMovePhoto: photoUploadMove,
};

export default connect(mapStateToProps, mapDispatchToProps)(PhotosUpload);
