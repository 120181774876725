import { faClipboard, faRedo, faStar, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { WIKI_PLACE_IMAGE_IN_ARTICLE_WITH_FOLLOWING_CODE } from 'mk/autogenerated/translations/PhotoPreview.6e5fb11cf81a967240011a0d81d56c45'
import { decidePreviewPhotoVersion, photoUrl } from 'mk/photo/photoUrl';
import { Btn, BtnLayout } from 'mk2/components/Btn';
import { CroppedPreview } from 'mk2/components/CroppedPreview';
import { ImgCropped, ImgCropMode } from 'mk2/components/ImgCropped';
import {
    FailedPhotoUpload,
    PendingPhotoUpload,
    PhotoUploadStatus,
    SuccessfulPhotoUpload,
} from 'mk2/helpers/form.reducers';
import { MapDispatchToPropsObject } from 'mk2/helpers/types';
import { AppState } from 'mk2/reducers';
import { PhotoEntity, PhotoOrientation, PhotoPreviewEntity, PhotoPreviewSchema, PhotoSchema } from 'mk2/schemas';
import { getDenormalizedEntity } from 'mk2/selectors';
import React from 'react';
import { connect } from 'react-redux';
import styles from './PhotoPreview.mscss';

export interface OwnProps {
    className?: string;
    upload: PendingPhotoUpload | SuccessfulPhotoUpload | FailedPhotoUpload;
    previewPhotoSize?: string;
    showPhotoCode: boolean;
    showStar?: boolean;
    isMain?: boolean;
    size?: number;
    noCrop?: boolean;
    onCancel?(uploadId: number);
    onRotate?(uploadId: number, orientation: PhotoOrientation);
}

interface OwnState {
    photoLoaded: boolean;
}

interface StateProps {
    uploadedPhoto: PhotoEntity | null; // not null if upload.status === UploadStatus.SUCCESS
    uploadedPhotoPreview: PhotoPreviewEntity | null; // not null if upload.status === UploadStatus.PENDING
}

interface DispatchProps {}

type Props = OwnProps & StateProps & DispatchProps;

const cache = new Set<string>();

class PhotoPreview extends React.Component<Props, OwnState> {
    private mounted: boolean;

    constructor(props: Props) {
        super(props);

        const { uploadedPhoto, previewPhotoSize, upload } = props;
        const photoVersion = decidePreviewPhotoVersion(previewPhotoSize, uploadedPhoto);

        this.state = {
            // Check if browser has already loaded this image url
            photoLoaded:
                upload.status === PhotoUploadStatus.SUCCESS && cache.has(photoUrl(uploadedPhoto, photoVersion)),
        };
    }

    public componentDidMount(): void {
        this.mounted = true;
        if (!this.state.photoLoaded && this.props.upload.status === PhotoUploadStatus.SUCCESS) {
            this.loadImage();
        }
    }

    public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<OwnState>, snapshot?: any): void {
        if (!this.state.photoLoaded && this.props.upload.status === PhotoUploadStatus.SUCCESS) {
            this.loadImage();
        }
    }

    public componentWillUnmount() {
        // Prevent load image to set state if component is unmounted
        this.mounted = false;
    }

    public render() {
        const {
            className,
            upload,
            uploadedPhoto,
            uploadedPhotoPreview,
            previewPhotoSize,
            showPhotoCode,
            onCancel,
            onRotate,
            showStar,
            isMain,
            size,
            noCrop,
        } = this.props;
        const photoLoadedAndUploadedSuccesfullyAnd =
            this.state.photoLoaded && upload.status === PhotoUploadStatus.SUCCESS;
        const photoVersion = decidePreviewPhotoVersion(previewPhotoSize, uploadedPhoto);

        return (
            <div
                className={cx(
                    styles.PhotoPreview,
                    upload.status === PhotoUploadStatus.FAILURE && styles['PhotoPreview--failure'],
                    className,
                )}
                data-cy="photo-preview"
            >
                {uploadedPhotoPreview && !photoLoadedAndUploadedSuccesfullyAnd && (
                    <CroppedPreview
                        className={cx(styles.PhotoPreview__photo)}
                        uploadProgress={upload.status === PhotoUploadStatus.PENDING ? upload.progress : 100}
                        imgUrl={uploadedPhotoPreview.imgBase64Data}
                        imgWidth={uploadedPhotoPreview.originalWidth}
                        imgHeight={uploadedPhotoPreview.originalHeight}
                        height={size || 90}
                        width={size || 90}
                        mode={noCrop ? ImgCropMode.Embed : ImgCropMode.Cover}
                    />
                )}
                {upload.status === PhotoUploadStatus.FAILURE && (
                    <div key="name" className={styles.PhotoPreview__name}>
                        {upload.fileName}
                    </div>
                )}
                {photoLoadedAndUploadedSuccesfullyAnd && (
                    <ImgCropped
                        className={cx(
                            styles.PhotoPreview__photo,
                            noCrop && styles['PhotoPreview__photo--noCrop'],
                            styles[
                                [
                                    'PhotoPreview__photo--rotated0',
                                    'PhotoPreview__photo--rotated90',
                                    'PhotoPreview__photo--rotated180',
                                    'PhotoPreview__photo--rotated270',
                                ][(upload as SuccessfulPhotoUpload).orientation]
                            ],
                        )}
                        photo={uploadedPhoto}
                        photoVersion={photoVersion}
                        height={size || 90}
                        width={size || 90}
                        mode={noCrop ? ImgCropMode.Embed : ImgCropMode.Cover}
                        lazy={false}
                        data-cy="photo-preview-img"
                    />
                )}
                {onCancel && (
                    <Btn
                        className={cx(styles.PhotoPreview__btn, styles['PhotoPreview__btn--topRight'])}
                        layout={BtnLayout.Icon}
                        icon={faTimes}
                        isAction
                        onClick={this.handleCancel}
                        data-cy="photo-preview-cancel"
                    />
                )}
                {onRotate && photoLoadedAndUploadedSuccesfullyAnd && (
                    <Btn
                        className={cx(styles.PhotoPreview__btn, styles['PhotoPreview__btn--bottomRight'])}
                        layout={BtnLayout.Icon}
                        icon={faRedo}
                        isAction
                        onClick={this.handleRotate}
                        data-cy="photo-preview-rotate"
                    />
                )}
                {showPhotoCode && photoLoadedAndUploadedSuccesfullyAnd && (
                    <Btn
                        className={cx(styles.PhotoPreview__btn, styles['PhotoPreview__btn--bottomLeft'])}
                        layout={BtnLayout.Icon}
                        icon={faClipboard}
                        isAction
                        onClick={this.handleShowPhotoCode}
                    />
                )}
                {showStar &&
                    photoLoadedAndUploadedSuccesfullyAnd &&
                    (isMain && (
                        <span className={cx(styles.PhotoPreview__star)}>
                            <FontAwesomeIcon icon={faStar} inverse className={styles.PhotoPreview__star__icon} />
                        </span>
                    ))}
            </div>
        );
    }

    private handleRotate = () => {
        const { upload, onRotate } = this.props;
        const supload = upload as SuccessfulPhotoUpload;
        onRotate(supload.id, (supload.orientation + 1) % 4);
    };

    private handleCancel = () => {
        const { upload, onCancel } = this.props;
        onCancel(upload.id);
    };

    private handleShowPhotoCode = () => {
        const { uploadedPhoto } = this.props;
        if (uploadedPhoto) {
            const pictureCode = '<<pic cd="' + uploadedPhoto.code + '" title="" caption="">>';
            prompt(WIKI_PLACE_IMAGE_IN_ARTICLE_WITH_FOLLOWING_CODE, pictureCode);
        }
    };

    private handleHiddenImageLoaded = (e) => {
        if (this.mounted) {
            this.setState({ photoLoaded: true });
        }

        const { uploadedPhoto, previewPhotoSize } = this.props;
        const photoVersion = decidePreviewPhotoVersion(previewPhotoSize, uploadedPhoto);
        cache.add(photoUrl(uploadedPhoto, photoVersion));
    };

    private handleHiddenImageFailed = (e) => {
        if (this.mounted) {
            this.setState({ photoLoaded: false });
        }
    };

    private loadImage() {
        const { uploadedPhoto, previewPhotoSize } = this.props;
        const photoVersion = decidePreviewPhotoVersion(previewPhotoSize, uploadedPhoto);
        const image = new Image();
        image.addEventListener('load', this.handleHiddenImageLoaded);
        image.addEventListener('error', this.handleHiddenImageFailed);
        image.src = photoUrl(uploadedPhoto, photoVersion);
    }
}

function mapStateToProps(state: AppState, ownProps: OwnProps): StateProps {
    const { upload } = ownProps;

    const uploadedPhoto: PhotoEntity =
        upload.status === PhotoUploadStatus.SUCCESS
            ? getDenormalizedEntity<PhotoEntity>(state, PhotoSchema, (upload as SuccessfulPhotoUpload).photoId)
            : null;
    const uploadedPhotoPreview: PhotoPreviewEntity = getDenormalizedEntity<PhotoPreviewEntity>(
        state,
        PhotoPreviewSchema,
        upload.fileName,
    );

    return {
        uploadedPhoto,
        uploadedPhotoPreview,
    };
}

const mapDispatchToProps: MapDispatchToPropsObject<DispatchProps> = {};

export default connect(mapStateToProps, mapDispatchToProps)(PhotoPreview);
